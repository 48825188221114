@file:OptIn(ExperimentalUuidApi::class)

package com.appcreator.client.firebase.storage

import androidx.compose.runtime.Composable
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.blueprint.firebase.storage.FirebaseStorageUploadImageAction
import com.appcreator.compose.LocalInputEnvStore
import com.appcreator.compose.actions.Performer
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.storage.File
import dev.gitlive.firebase.storage.storage
import dev.gitlive.firebase.storage.storageMetadata
import io.github.vinceglb.filekit.core.FileKit
import io.github.vinceglb.filekit.core.PickerMode
import io.github.vinceglb.filekit.core.PickerType
import io.github.vinceglb.filekit.core.PlatformFile
import io.github.vinceglb.filekit.core.extension
import kotlinx.coroutines.flow.collectLatest
import kotlin.uuid.ExperimentalUuidApi
import kotlin.uuid.Uuid

class UploadImagePerformer(private val action: FirebaseStorageUploadImageAction): Performer {

    @Composable
    override fun deferred(): Performer.Deferred {
        val inputStore = LocalInputEnvStore.current
        return object : Performer.Deferred {
            override suspend fun perform(envStore: EnvStore) {
                val (type, title) = when {
                    action.allowImages == true && action.allowVideos == true -> PickerType.ImageAndVideo to "Pick a file"
                    action.allowVideos == true -> PickerType.Video to "Pick a video"
                    else -> PickerType.Video to "Pick an image"
                }

                FileKit.pickFile(
                    type = type,
                    mode = PickerMode.Single,
                    title = title
                )?.let { file ->

                    val metadata = storageMetadata {
                        contentType = file.extension
                    }

                    val name = if(action.imageName?.isNotBlank() == true) {
                        envStore.injectVariables(action.imageName!!) + ".${file.extension}"
                    } else file.name

                    val ref = Firebase.storage.reference.child(name)
                    val progressFlow = ref.putFileResumable(file.toFile(), metadata)

                    progressFlow.collectLatest {
                        action.progressOutput?.let { progressOutput ->
                            val progress = (it.bytesTransferred.toLong() * 100) / it.totalByteCount.toLong()
                            inputStore.set(progressOutput.value, progress.toString())
                        }
                    }

                    action.urlOutput?.let {
                        inputStore.set(it.value, ref.getDownloadUrl())
                    }
                }
            }
        }
    }
}

expect fun PlatformFile.toFile(): File