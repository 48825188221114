package com.appcreator.client.firebase.installations


import androidx.compose.runtime.mutableStateOf
import com.appcreator.blueprint.firebase.installations.FirebaseInstallationsConsts
import com.appcreator.compose.di.Container
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.installations.installations
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch

object FirebaseInstallationsInitializer {
    fun initialize() {

        val installationID = mutableStateOf("")
        Container.globalKeys[FirebaseInstallationsConsts.installationID] = installationID

        GlobalScope.launch {
            try {
                installationID.value = Firebase.installations.getId()
            } catch (ex: Exception) {
                ex.printStackTrace()
            }
        }
    }
}

