package com.appcreator.client.revenuecat

import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.platform.LocalUriHandler
import com.appcreator.blueprint.actions.navigation.OpenExternalAction
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.blueprint.revenuecat.RevenuecatManageSubscriptionsAction
import com.appcreator.blueprint.revenuecat.RevenuecatPaywallComponent
import com.appcreator.compose.actions.Performer

class ManageSubscriptionsPerformer(private val action: RevenuecatManageSubscriptionsAction): Performer {

    @Composable
    override fun deferred(): Performer.Deferred {
        val navigator = LocalUriHandler.current
        return object : Performer.Deferred {
            override suspend fun perform(envStore: EnvStore) {
                loadManageSubscriptionsUrl()?.let {
                    navigator.openUri(it)
                }
            }
        }
    }

}

internal expect suspend fun loadManageSubscriptionsUrl(): String?