package com.appcreator.client.firebase.auth

import androidx.compose.runtime.Composable
import com.appcreator.blueprint.core.EnvStore
import com.appcreator.blueprint.firebase.auth.FirebaseAuthLogoutAction
import com.appcreator.compose.actions.Performer
import com.appcreator.compose.di.Container
import com.appcreator.compose.events.LoginEvent
import dev.gitlive.firebase.Firebase
import dev.gitlive.firebase.auth.auth

class LogoutPerformer(val action: FirebaseAuthLogoutAction): Performer {
    @Composable
    override fun deferred(): Performer.Deferred {
        return object : Performer.Deferred {
            override suspend fun perform(envStore: EnvStore) {
                Firebase.auth.signOut()
                Container.sendEvent(LoginEvent(null, null, null))
            }
        }
    }
}