package com.appcreator.compose.envfunctions

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import coil3.PlatformContext
import coil3.compose.LocalPlatformContext
import com.appcreator.blueprint.core.EnvFunction
import com.appcreator.compose.BlueprintConfig
import kotlinx.datetime.Clock


// This is a slap dash un-documents feature
@Composable
fun rememberEnvFunctions(config: BlueprintConfig): Map<String, EnvFunction> {
    val context = LocalPlatformContext.current
    return remember {
        mapOf(
            "now()" to EnvFunction { _, staticPass -> if(staticPass) "now" else Clock.System.now().toString() },
            "appVersion()" to EnvFunction { _, _ -> appVersion(context) },
            "deviceName()" to EnvFunction { _, _ -> deviceName() },
            "deviceVersion()" to EnvFunction { _, _ -> deviceVersion() },
            "platformName()" to EnvFunction { _, _ -> platformName() },
            "appStoreUrl()" to EnvFunction { _, _ -> appStoreUrl(context, config) }
        )
    }
}

expect fun platformName(): String
expect fun appVersion(platform: PlatformContext): String
expect fun deviceName(): String
expect fun deviceVersion(): String
expect fun appStoreUrl(platform: PlatformContext, config: BlueprintConfig): String