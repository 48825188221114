package com.appcreator.client.revenuecat

import com.appcreator.blueprint.core.EnvStore
import com.appcreator.blueprint.revenuecat.RevenuecatHasEntitlementCondition
import com.appcreator.compose.conditions.Evaluator

class HasEntitlementEvaluator(private val condition: RevenuecatHasEntitlementCondition): Evaluator {
    override fun evaluateOnce(envStore: EnvStore): Boolean = evaluatePerPlatform(condition, envStore)
    override suspend fun evaluateAsync(envStore: EnvStore): Boolean = evaluateAsyncPerPlatform(condition, envStore)
}

expect fun HasEntitlementEvaluator.evaluatePerPlatform(condition: RevenuecatHasEntitlementCondition, envStore: EnvStore): Boolean
expect suspend fun HasEntitlementEvaluator.evaluateAsyncPerPlatform(condition: RevenuecatHasEntitlementCondition, envStore: EnvStore): Boolean